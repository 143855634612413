<template>
  <!-- ======= Featured Services Section ======= -->
  <section id="featured-services" class="featured-services section-bg">
    <div class="container">
      <div class="row no-gutters">
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-stack"></i></div>
            <h3 class="title">Quality Code</h3>
            <p class="description">
              We know that the success of your web app depends on the quality of
              the code behind it. That's why we use only the best coding
              practices and frameworks to ensure your web app is robust,
              scalable, and secure. You can trust that our code will stand the
              test of time and provide a solid foundation for your web app.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-hand-thumbs-up-fill"></i></div>
            <h4 class="title">Professional Service</h4>
            <p class="description">
              Our team takes pride in providing professional and reliable
              service to all our clients. We understand the importance of clear
              communication, timely delivery, and exceptional customer service.
              You can trust us to be your dedicated partner throughout the
              entire web app development process.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-briefcase-fill"></i></div>
            <h4 class="title">Expertise and Experience</h4>
            <p class="description">
              We have a team of experienced and skilled web app developers who
              have a proven track record of delivering successful web app
              projects. We take pride in our expertise and knowledge of the
              latest web development trends and technologies. You can trust us
              to provide expert guidance and advice to ensure your web app is
              designed, developed, and deployed to perfection.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Featured Services Section -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/
.featured-services {
  padding: 20px 0;
}

.featured-services .icon-box {
  padding: 20px;
}

.featured-services .icon-box-bg {
  background-image: linear-gradient(
    0deg,
    #222222 0%,
    #2f2f2f 50%,
    #222222 100%
  );
}

.featured-services .icon {
  margin-bottom: 15px;
}

.featured-services .icon i {
  color: $mainLightBlue;
  font-size: 42px;
}

.featured-services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #222222;
  transition: 0.3s;
}

.featured-services .icon-box:hover .title a {
  color: $mainLightBlue;
}

.featured-services .description {
  line-height: 24px;
  font-size: 14px;
}
</style>