<template>
  <!-- ======= Services Section ======= -->
  <section id="services" class="services pt-3">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div
            class="icon-box iconbox-blue item-one border border-black rounded p-3"
          >
            <h4 class="text-black text-center">Development</h4>
            <p class="text-black text-center">
              We understand that every business has unique needs and challenges.
              That's why we offer custom web app development services to help
              you build the perfect web app for your specific requirements. Our
              expert team of developers will work closely with you to understand
              your business goals and develop a tailored solution that meets
              your needs, from planning and design to development, testing, and
              launch.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <div
            class="icon-box iconbox-orange item-two border border-black rounded p-3"
          >
            <h4 class="text-black text-center">Design</h4>
            <p class="text-black text-center">
              We know that great design is key to building successful web apps.
              That's why we offer expert web app design services to help you
              create a user-friendly, visually appealing interface that engages
              your target audience. Our design team has years of experience in
              creating stunning, intuitive designs that are optimized for
              performance and user experience.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          <div
            class="icon-box iconbox-pink item-three border border-black rounded p-3"
          >
            <h4 class="text-black text-center">Maintenance and Support</h4>
            <p class="text-black text-center">
              We believe that building a successful web app is just the
              beginning. That's why we offer ongoing maintenance and support
              services to ensure that your web app remains up-to-date, secure,
              and optimized for performance. Our dedicated team of support
              specialists will work with you to ensure that your web app runs
              smoothly and that any issues are quickly resolved, so you can
              focus on growing your business.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div
            class="icon-box iconbox-yellow item-four border border-black rounded p-3"
          >
            <h4 class="text-black text-center">Content Management System</h4>
            <p class="text-black text-center">
              We know that integrating and migrating web apps can be challenging
              and disruptive. That's why we offer web app integration and
              migration services to help you seamlessly integrate your web app
              with other business systems and migrate your web app to a new
              platform. Our team of experts has experience working with a
              variety of integration and migration tools and platforms, such as
              Zapier, Integromat, and Amazon Web Services, and can provide
              customized solutions that meet your business needs. We can also
              help you optimize your web app performance and improve your user
              experience during the integration and migration process.
            </p>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <div
            class="icon-box iconbox-red item-five border border-black rounded p-3"
          >
            <h4 class="text-black text-center">Integration</h4>
            <p class="text-black text-center">
              We know that many businesses rely on multiple software
              applications to run their operations. That's why we offer expert
              web app integration services to help you seamlessly integrate your
              web app with other software systems, such as CRMs, ERPs, and more.
              Our developers have extensive experience in integrating web apps
              with a wide range of software solutions, so you can streamline
              your workflows and improve your productivity.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          <div
            class="icon-box iconbox-teal item-six border border-black rounded p-3"
          >
            <h4 class="text-black text-center">E-commerce</h4>
            <p class="text-black text-center">
              We understand the unique needs of e-commerce businesses. That's
              why we offer expert e-commerce web app development services to
              help you build a robust, secure, and scalable e-commerce platform
              that can handle your growing sales volumes. Our team of developers
              has extensive experience in developing e-commerce web apps with
              advanced features, such as shopping carts, payment gateways, and
              inventory management systems, so you can provide your customers
              with a seamless shopping experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services Section -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
// .services .icon-box {
//   text-align: center;
//   padding: 70px 20px 80px 20px;
//   transition: all ease-in-out 0.3s;
//   box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
// }

.services .icon-box .icon {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
}

.services .icon-box .icon i {
  font-size: 36px;
  transition: 0.5s;
  position: relative;
}

.services .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.services .icon-box .icon svg path {
  transition: 0.5s;
  fill: #f5f5f5;
}

.services .icon-box h4 {
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 22px;
}

.services .icon-box h4 a {
  color: $mainWhite;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  color: $mainWhite;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
}

.services .iconbox-blue i {
  color: #47aeff;
}

.services .iconbox-blue:hover .icon i {
  color: #fff;
}

.services .iconbox-blue:hover .icon path {
  fill: #47aeff;
}

.services .iconbox-orange i {
  color: #ffa76e;
}

.services .iconbox-orange:hover .icon i {
  color: #fff;
}

.services .iconbox-orange:hover .icon path {
  fill: #ffa76e;
}

.services .iconbox-pink i {
  color: #e80368;
}

.services .iconbox-pink:hover .icon i {
  color: #fff;
}

.services .iconbox-pink:hover .icon path {
  fill: #e80368;
}

.services .iconbox-yellow i {
  color: #ffbb2c;
}

.services .iconbox-yellow:hover .icon i {
  color: #fff;
}

.services .iconbox-yellow:hover .icon path {
  fill: #ffbb2c;
}

.services .iconbox-red i {
  color: #ff5828;
}

.services .iconbox-red:hover .icon i {
  color: #fff;
}

.services .iconbox-red:hover .icon path {
  fill: #ff5828;
}

.services .iconbox-teal i {
  color: #11dbcf;
}

.services .iconbox-teal:hover .icon i {
  color: #fff;
}

.services .iconbox-teal:hover .icon path {
  fill: #11dbcf;
}
</style>