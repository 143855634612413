<template>
  <!-- ======= About Us Section ======= -->
  <section id="about" class="about">
    <div class="container">
      <div class="section-title">
        <h2>About Us</h2>
        <p>Innovative Web App Solutions for Your Business</p>
      </div>

      <div class="row">
        <div class="col-lg-6 order-1 order-lg-2">
          <img
            src="../../assets/img/about-3.jpg"
            class="img-fluid"
            alt="image"
          />
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <h3>Transform Your Ideas into High-Performing Web Apps</h3>
          <p class="mt-3">
            With years of experience in the industry, we have a deep
            understanding of the latest web development trends and technologies.
            We use only the best coding practices, frameworks, and tools to
            ensure your web app is secure, scalable, and user-friendly. Whether
            you're looking to build a new web app from scratch or improve an
            existing one, we have the expertise and knowledge to make it happen.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Us Section -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 28px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #5cb874;
}

.about .content p:last-child {
  margin-bottom: 0;
}
</style>