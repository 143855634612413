<template>
  <!-- ======= Why Us Section ======= -->
  <section id="why-us" class="why-us">
    <div class="container">
      <div class="row no-gutters ms-3 me-3 ms-md-0 me-md-0">
        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(1) }}</span>
          <h4>Experience</h4>
          <p>
            We have years of experience in the web development industry. We have
            worked with a wide variety of clients, from startups to established
            businesses, and we have the expertise needed to deliver results. Our
            team is always staying up-to-date with the latest trends and
            technologies, so we can provide our clients with the best possible
            service.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(2) }}</span>
          <h4>Attention to Detail</h4>
          <p>
            We believe that the little things matter when it comes to web
            development. That's why we pay close attention to every detail of
            our projects, from the layout to the functionality. We strive for
            perfection in everything we do, so our clients can be confident that
            their website will be top-notch.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(3) }}</span>
          <h4>Creative Approach</h4>
          <p>
            We don't just create websites, we craft experiences. We take a
            creative approach to every project, finding unique solutions that
            are tailored to our clients' needs. Our team of designers and
            developers work together to create beautiful and functional websites
            that make an impact.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(4) }}</span>
          <h4>Commitment to Customer Satisfaction</h4>
          <p>
            Our top priority is customer satisfaction. We are committed to
            providing our clients with the best possible service, from start to
            finish. We go above and beyond to make sure our clients are happy
            with the end result, and we're not satisfied until they are.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(5) }}</span>
          <h4>Communication</h4>
          <p>
            We believe that communication is key to a successful project. That's
            why we keep our clients in the loop every step of the way. We are
            always available to answer questions, provide updates, and discuss
            any concerns our clients may have.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(6) }}</span>
          <h4>Affordable Pricing</h4>
          <p>
            We believe that high-quality web development shouldn't break the
            bank. That's why we offer affordable pricing options for our
            clients, without sacrificing quality. We work within our clients'
            budgets to provide the best possible service at a fair price.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- End Why Us Section -->
</template>

<script>
export default {
  methods: {
    intToRoman(num) {
      if (num < 1 || num > 3999) {
        return "Invalid number";
      }

      const romanNums = [
        "M",
        "CM",
        "D",
        "CD",
        "C",
        "XC",
        "L",
        "XL",
        "X",
        "IX",
        "V",
        "IV",
        "I",
      ];
      const integerNums = [
        1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1,
      ];

      let romanStr = "";

      for (let i = 0; i < integerNums.length; i++) {
        while (num >= integerNums[i]) {
          romanStr += romanNums[i];
          num -= integerNums[i];
        }
      }

      return romanStr;
    },
  },
};
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
  padding: 0;
}

.why-us .row {
  overflow: hidden;
}

.why-us .content-item {
  padding: 40px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: -1px;
}

.why-us .content-item span {
  display: block;
  font-size: 24px;
  font-weight: 400;
  color: $mainLightBlue;
}

.why-us .content-item h4 {
  font-size: 26px;
  font-weight: 300;
  padding: 0;
  margin: 20px 0;
}

.why-us .content-item p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .why-us .content-item {
    padding: 40px 0;
  }
}
</style>